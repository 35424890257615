@layer overrides {
  .menu-stack {
    padding: '0.75rem';
    box-shadow: '0 0 5px rgba(0,0,0,0.1)';
    border: '1px solid var(--mantine-colors-gray-2)';
    border-radius: '0.5rem';
  }

  .top-bar {
    border: solid 2px var(--mantine-colors-brand-6);
    border-radius: 0.25rem;
    background-color: var(--mantine-colors-brand-6);
    text-align: center;
    cursor: pointer;
    display: block;
    color: white;
    &:hover {
      border-color: white;
      color: var(--mantine-colors-brand-6);
      background-color: white;
      a {
        color: var(--mantine-colors-brand-6);
      }
    }

    &.active {
      color: var(--mantine-colors-brand-6);
      background-color: white;
      border: solid 2px white;
      border-radius: 0.25rem;
      &:hover {
        border: solid 2px var(--mantine-colors-brand-6);
        background-color: var(--mantine-colors-brand-6);
      }
    }
  }

  .top-bar a {
    color: var(--mantine-color-brand-6);
    text-decoration: none;
    &:hover {
      background-color: var(--mantine-color-brand-6);
      a {
        color: var(--mantine-color-brand-6);
      }
    }
  }

  .userOptionsContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 6 0 0;
    border: 1px solid white;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  .appTrayContainer {
    position: absolute;
    display: flex;
    top: 50px;
    right: 76px;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    padding: 8px;
    border: 1px solid white;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  .topnav {
    padding: 0;
    margin: 0;
    height: 54px;
    background-color: var(--mantine-color-brand-6);
  }

  @media (max-width: 1200px) {
    .top-bar-container {
      display: none !important;
    }
  }
}
