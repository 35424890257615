@layer overrides {
  .appContainer {
    display: grid;
    grid-template-columns: 200px auto;
    margin: 0;
    padding: 0;
    position: relative;
    background: var(--mantine-color-brand-6);
    transition: 300ms ease-in-out;
  }

  .appContainer.closedState {
    transition: 300ms ease-in-out;
    grid-template-columns: 60px auto;
  }

  .appContainer.public {
    grid-template-columns: unset;
  }

  .appHeader {
    position: fixed;
    height: 60px;
    width: 100%;
    background-color: var(--mantine-color-brand-6);
    z-index: 999;
    padding-left: 15px;
  }

  /* .appHeader.public {
    display: none;
  } */

  .navContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: scroll;
  }

  nav.public {
    display: none;
  }

  .contentContainer {
    padding-left: 22px;
    padding-right: 12px;
    padding-top: 12px;
    margin-top: 60px;
    border: 1px solid var(--mantine-color-brand-6);
    border-radius: 12px 0 0 0;
    border-style: solid;
    background: var(--mantine-color-gray-0);
  }

  .contentContainer.closedState {
    padding-right: 12px;
  }

  .toggleBurger {
    cursor: pointer;
  }

  .menu {
    background-color: var(--mantine-color-brand-6);
    padding-top: 60px;
    padding-left: 0px;
    box-sizing: border-box;
    height: 100vh;
    width: 200px;
    position: sticky;
    top: 0px;
    align-self: start;
    transition: 300ms ease-in-out;
    overflow: hidden;
    text-wrap: nowrap;

    .navSubheading {
      padding-left: 12px;
      padding-top: 6px;

      p {
        font-size: 0.8em;
        font-style: italic;
      }
    }

    ul {
      list-style: none;
      color: white;
      margin: 0;
      padding: 0;
    }

    ul li span {
      padding-left: 0.8em;
    }

    ul li.active {
      color: #61deb0;
      svg {
        fill: #61deb0;
      }
    }

    li {
      width: 200px;
    }

    a,
    .dropdownbtn {
      border-radius: 0.5em;
      padding: 0.85em;
      padding-right: 0em;
      text-decoration: none;
      color: white;
      display: flex;
      align-items: center;
      gap: 0.5em;
    }

    .dropdownbtn {
      width: 190px;
      text-align: left;
      background: none;
      border: none;
      font: inherit;
      cursor: pointer;
    }

    .togglebtn {
      background: none;
      border: none;
      margin-left: auto;
      padding: 0em;
      border-radius: 0.5em;
      cursor: pointer;
      margin-top: -6px;

      svg {
        transition: rotate 150ms ease;
      }

      &:hover {
        background-color: var(--mantine-color-brand-7);
      }
    }

    svg {
      flex-shrink: 0;
    }

    a span,
    .dropdownbtn span {
      flex-grow: 1;
    }

    a:hover,
    .dropdownbtn:hover {
      background-color: var(--mantine-color-brand-7);
      cursor: pointer;
    }

    .submenu {
      display: grid;
      grid-template-rows: 0fr;
      transition: 150ms ease-in-out;

      > div {
        overflow: hidden;
      }

      a {
        padding-left: 2em;
        padding-bottom: 0.2em;
        padding-top: 0.25em;
      }

      .active {
        color: #61deb0;
      }
    }

    .submenu.show {
      grid-template-rows: 1fr;
    }
  }

  .menu.closedState {
    width: 60px;
  }

  .subMenu {
    flex-grow: 1;
    /* padding-top: 0.5rem; */
    margin-top: calc(var(--mantine-spacing-sm) * -1);
    padding-top: var(--mantine-spacing-sm);
    box-shadow: -0.5rem 0.1rem 0.75rem var(--mantine-color-gray-2);
    padding-left: 0.5rem;
    border-right: none;
    /* border-radius: 0.5rem 0 0 0.5rem; */
  }
  .menu:last-of-type {
    padding-right: var(--mantine-spacing-sm);
  }

  .dropdownbtn svg {
    transition: 200ms ease;
  }

  .rotate svg:last-child {
    rotate: 180deg;
  }

  .sectionContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    padding: 12px;
    width: fit-content;
  }

  .searchIconContainer {
    background-color: var(--mantine-color-brand-7);
    height: 36px;
    margin-top: -12px;
    width: 3em;
    display: flex;

    justify-content: center;
    align-items: center;
    margin: -48px;
    z-index: 999;
    border-radius: 0 20px 20px 0;
    border-color: var(--mantine-color-gray-5);
    border-width: 1px 1px 1px 0;
    border-style: solid;
  }

  .menuSeparator {
    width: 200px;
    opacity: 0.5;
  }
}
